import React, { useEffect, useState, CSSProperties } from "react";
import { COUNTRY_CODE } from '../../../Api/Api';
import ChooseNovaPraiaCaparica from "../../../assets/Locations/nova-praia-caparica-choose.png";
import ChooseBarDoBrunoBaleal from "../../../assets/Locations/bar-do-bruno-baleal-choose.png";
import ChooseTunnelCarcavelos from "../../../assets/Locations/tunnel-carcavelos-choose.png";
import ChooseTarquinioCaparica from "../../../assets/Locations/tarquinio-caparica-choose.png";
import ChooseFonteDaTelhaCarparica from "../../../assets/Locations/fonte-de-telha-caparica-choose.png";
import ChooseAljezurArrifana from "../../../assets/Locations/arrifana-aljezur-choose.png";
import ChooseComingSoon from "../../../assets/Locations/comingsoon-choose.png";
import ChooseSubscription from "../../../assets/Locations/subscription-choose.png";
import styles from "./TimeAndPlaceSelector.module.css";
import CaparicaSponsor from "../../../assets/Locations/caparica-sponsor.png";
import CarcavelosSponsor1 from "../../../assets/Locations/carcavelos-sponsor1.png";
import CarcavelosSponsor2 from "../../../assets/Locations/carcavelos-sponsor2.png";
import BarDoBrunoBalealSponsor1 from "../../../assets/Locations/bar-do-bruno-baleal-sponsor1.png";
import BarDoBrunoBalealSponsor2 from "../../../assets/Locations/bar-do-bruno-baleal-sponsor2.png";
import FonteDaTelhaCarparicaSponsor from "../../../assets/Locations/fonte-de-telha-caparica-sponsor.png";
import AljezurArrifanaSponsor from "../../../assets/Locations/arrifana-aljezur-sponsor.png";
import ChoosePTCaparicaSantoAntonio from "../../../assets/Locations/pt-caparica-santo-antonio-choose.png";
import ChoosePTEriceriaRibDilhas from "../../../assets/Locations/pt-ericeria-ribeira-dilhas-choose.png";
import PTCaparicaSantoAntonioSponsor from "../../../assets/Locations/pt-caparica-santo-antonio-sponsor.png";
import PTCaparicaTarquinioSponsor from "../../../assets/Locations/tarquinio-caparica-sponsor.png";


import watchStyles from "../../../pages/AppLayout.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { endpoints } from "../../../Api/Api";



type LocationRenderProps = {
    style?: CSSProperties,
    img: string,
    text1: string,
    text2: string,
    text3?: string,
    disabled?: boolean,
    onClick?: () => void,
    banner?: string,
    sponsors?: Array<string>,
    borderOfLocation?: string
  }
  
  const LocationRender = ({ img, text1, text2, text3 = "", style, onClick, disabled, sponsors, banner, borderOfLocation="none" }: LocationRenderProps) => {
    const [mouseOver, setMouseOver] = useState(false);
    return (
      <div className={`${styles["LocationRender-wrapper"]} ${borderOfLocation === "month" ? styles.unmimitedBox : (borderOfLocation === "day" ? styles.dayPassBox : "")}`}>

        {/* <div style={{  }}> */}
          <img src={img} className={styles["LocationRender-wrapper-img"]} />
          <div className={`${styles["LocationRender-background"]} ${mouseOver && styles["LocationRender-background-mouseOver"]}`}>
            <div className={styles["LocationRender-label"]}><span style={{ fontWeight: 'bold' }}>{text1}</span><br /><span>{text2}</span><br /><span>{text3}</span> </div></div>
          <div
            className={styles["LocationRender-sponsor"]}>
            {sponsors && sponsors.map((s) => <img src={s} />)}
          </div>
          {banner && <div className={styles.banner}><a>{banner}</a></div>}
          <div className={styles["LocationRender-mouse-shim"]}
            onMouseOver={() => { setMouseOver(true && !disabled) }}
            onMouseOut={() => { setMouseOver(false) }}
            onClick={() => {
              if (onClick != undefined) {
                onClick();
              }
            }}
          ></div>
        {/* </div> */}
        {borderOfLocation === "month" ? <h6 className={styles["lableShow"]}>unlimited</h6>: (borderOfLocation === "day" ? <h6 className={styles["lableShow"]}>DAY PASS</h6> : "")}
        {/* <h6 className={styles["lableShow"]}>unlimited</h6> */}
        {/* <h6 className={styles["lableShow"]}>DAY PASS</h6> */}
      </div>
    );
  }

export const PlaceSelector = () => {
    const navigate = useNavigate();
    const [AllPassDetails, setAllPassDetails] = useState({
      "All":false,
      "caparica-tarquinio":false,
      "carcavelos-tunnel":false,
      "nova-praia":false,
      "peniche-bar-do-bruno":false,
      "caparica-fonte-da-telha":false,
      "pt-caparica-santo-antonio":false,
      "pt-ericeira-ribeira-dilhas":false,
      "aljezur-arrifana":false,
      "br-sp-guaruja-praia-do-tombo":false,
      "br-sp-maresias-sao-sebastiao":false,
      "br-sp-ubatuba-itamambuca":false,
      "br-sp-ubatuba-vermelha-do-norte":false
    })
    useEffect(() => {
      const customertoken = localStorage.getItem("token")
      axios.get(endpoints.GET_ACTIVE_PASS,
      {
        headers: {
          Authorization: `Bearer ${customertoken}`,
        },
      }
      ).then((response) => {
        const allDetails = response?.data?.data;
        const All = Object.keys(allDetails["All"]).length; 
        const caparica_tarquinio = Object.keys(allDetails["caparica-tarquinio"]).length; 
        const carcavelos_tunnel = Object.keys(allDetails["carcavelos-tunnel"]).length; 
        const nova_praia = Object.keys(allDetails["nova-praia"]).length; 
        const peniche_bar_do_bruno = Object.keys(allDetails["peniche-bar-do-bruno"]).length;
        const caparica_fonte_da_telha = Object.keys(allDetails["caparica-fonte-da-telha"]).length;
        const pt_caparica_santo_antonio = Object.keys(allDetails["pt-caparica-santo-antonio"]).length;
        const pt_ericeira_ribeiradilhas = Object.keys(allDetails["pt-ericeira-ribeira-dilhas"]).length;
        const aljezur_arrifana = Object.keys(allDetails["aljezur-arrifana"]).length;
        const br_sp_guaruja_praia_do_tombo = Object.keys(allDetails["br-sp-guaruja-praia-do-tombo"]).length;
        const br_sp_maresias_sao_sebastiao = Object.keys(allDetails["br-sp-maresias-sao-sebastiao"]).length;
        const br_sp_ubatuba_itamambuca = Object.keys(allDetails["br-sp-ubatuba-itamambuca"]).length;
        const br_sp_ubatuba_vermelha_do_norte = Object.keys(allDetails["br-sp-ubatuba-vermelha-do-norte"]).length;

        let obj = {
          "All": (All > 0 ? true : false),
          "caparica-tarquinio":(caparica_tarquinio > 0 ? true : false),
          "carcavelos-tunnel":(carcavelos_tunnel > 0 ? true : false),
          "nova-praia":(nova_praia > 0 ? true : false),
          "peniche-bar-do-bruno":(peniche_bar_do_bruno > 0 ? true : false),
          "caparica-fonte-da-telha":(caparica_fonte_da_telha > 0 ? true : false),
          "pt-caparica-santo-antonio":(pt_caparica_santo_antonio > 0 ? true : false),
          "pt-ericeira-ribeira-dilhas":(pt_ericeira_ribeiradilhas > 0 ? true : false),
          "aljezur-arrifana":(aljezur_arrifana > 0 ? true : false),
          "br-sp-guaruja-praia-do-tombo":(br_sp_guaruja_praia_do_tombo > 0 ? true : false),
          "br-sp-maresias-sao-sebastiao":(br_sp_maresias_sao_sebastiao > 0 ? true : false),
          "br-sp-ubatuba-itamambuca":(br_sp_ubatuba_itamambuca > 0 ? true : false),
          "br-sp-ubatuba-vermelha-do-norte":(br_sp_ubatuba_vermelha_do_norte > 0 ? true : false)
        }
        setAllPassDetails(obj)
    
      })
        .catch((err) => {
          console.log(err, "eror while get pass info!!")
      })
  
    }, [])
    // useEffect(() => {
    //     waveService.send({
    //         type: 'reset'
    //     });
    // }, []);
    return (
      <div className={watchStyles.watch}>
          {COUNTRY_CODE === "BR" ? (
            <div className={watchStyles.watchContainer}>
            <h2 className={styles.header} >Onde você surfou?</h2>
            <div className={styles.locationWrapper}>
              <LocationRender 
                onClick={() => {
                  navigate('location/br-sp-guaruja-praia-do-tombo');
                }}
                sponsors={[]}
                style={{ position: 'relative' }} img={ChooseBarDoBrunoBaleal} text1="Praia do Tombo" text2="Guarujá" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["br-sp-guaruja-praia-do-tombo"] ? "day" : "none"): "month"}/>
              <LocationRender 
                onClick={() => {
                  navigate('location/br-sp-maresias-sao-sebastiao');
                }}
                sponsors={[]}
                style={{ position: 'relative' }} img={ChooseBarDoBrunoBaleal} text1="Maresias" text2="São Sebastiao" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["br-sp-maresias-sao-sebastiao"] ? "day" : "none"): "month"}/>
              <LocationRender 
                onClick={() => {
                  navigate('location/br-sp-ubatuba-itamambuca');
                }}
                sponsors={[]}
                style={{ position: 'relative' }} img={ChooseBarDoBrunoBaleal} text1="Itamambuca" text2="Ubatuba" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["br-sp-ubatuba-itamambuca"] ? "day" : "none"): "month"}/>
              <LocationRender 
                onClick={() => {
                  navigate('location/br-sp-ubatuba-vermelha-do-norte');
                }}
                sponsors={[]}
                style={{ position: 'relative' }} img={ChooseBarDoBrunoBaleal} text1="Vermelha do Norte" text2="Ubatuba" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["br-sp-ubatuba-vermelha-do-norte"] ? "day" : "none"): "month"}/>
            </div>
            </div>
          ) : (
            <div className={watchStyles.watchContainer}>
            <h2 className={styles.header} >Where did you surf?</h2>
            <div className={styles.locationWrapper}>
              <LocationRender 
              onClick={() => {
                navigate('location/peniche-bar-do-bruno');
              }}
              sponsors={[BarDoBrunoBalealSponsor1, BarDoBrunoBalealSponsor2]}
              style={{ position: 'relative' }} img={ChooseBarDoBrunoBaleal} text1="Bar do Bruno" text2="Baleal, Peniche" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["peniche-bar-do-bruno"] ? "day" : "none"): "month"}/>
              <LocationRender 
              onClick={() => {
                navigate('location/pt-ericeira-ribeira-dilhas');
              }}
              sponsors={[]}
              style={{ position: 'relative' }} img={ChoosePTEriceriaRibDilhas} text1="Praia de Ribeira d'Ilhas" text2="Ericeria" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["pt-ericeira-ribeira-dilhas"] ? "day" : "none"): "month"}/>
              <LocationRender 
              onClick={() => {
                navigate('location/carcavelos-tunnel');
              }}
              sponsors={[CarcavelosSponsor1, CarcavelosSponsor2]}
              style={{ position: 'relative' }} img={ChooseTunnelCarcavelos} text1="Tunnel" text2="Carcavelos" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["carcavelos-tunnel"] ? "day" : "none"): "month"}/>
            <LocationRender onClick={() => {
              navigate('location/caparica-tarquinio');
            }}
              sponsors={[PTCaparicaTarquinioSponsor]}
              style={{ position: 'relative' }} img={ChooseTarquinioCaparica} text1="Praia do Tarquínio" text2="Costa da caparica" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["caparica-tarquinio"] ? "day" : "none"): "month"}/>
            <LocationRender 
            onClick={() => {
              navigate('location/pt-caparica-santo-antonio');
            }}
              banner="Soon"
              sponsors={[PTCaparicaSantoAntonioSponsor]}
              style={{ position: 'relative' }} img={ChoosePTCaparicaSantoAntonio} text1="Praia de Santo António" text2="Costa da caparica" text3="" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["pt-caparica-santo-antonio"] ? "day" : "none"): "month"}/>
            <LocationRender onClick={() => {
              navigate('location/nova-praia');
            }}
              sponsors={[CaparicaSponsor]}
              style={{ position: 'relative' }} img={ChooseNovaPraiaCaparica} text1="Nova Praia" text2="Costa da caparica" text3="" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["nova-praia"] ? "day" : "none"): "month"}/>
            <LocationRender onClick={() => {
              navigate('location/caparica-fonte-da-telha');
            }}
              sponsors={[FonteDaTelhaCarparicaSponsor]}
              style={{ position: 'relative' }} img={ChooseFonteDaTelhaCarparica} text1="Swara" text2="Fonte Da Telha" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["caparica-fonte-da-telha"] ? "day" : "none"): "month"}/>
            <LocationRender 
            onClick={() => {
              navigate('location/aljezur-arrifana');
            }}
              sponsors={[AljezurArrifanaSponsor]}
              style={{ position: 'relative' }} img={ChooseAljezurArrifana} text1="Arrifana" text2="Aljezur" borderOfLocation={!AllPassDetails["All"] ? (AllPassDetails["aljezur-arrifana"] ? "day" : "none"): "month"}/>
            <LocationRender style={{ position: 'relative' }} img={ChooseComingSoon} text1="More" text2="Coming Soon" disabled={true} />
            {/* <LocationRender style={{ position: 'relative' }} img={ChooseComingSoon} text1="" text2="Coming Soon" disabled={true} /> */}
            </div>  
            </div>
              )}
      
      </div>

    );
}