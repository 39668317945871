import React, { useState } from "react";
import styles from "./NewCheckoutFooter.module.css";
import { useWaveContext } from "../../../../context/CartContext";
import { useActor } from "@xstate/react";
import logostripe from "../../../../assets/logostripe.png";
import StripeCheckout, { type Token } from "react-stripe-checkout";

type NewCheckoutFooterProps = {
  onNavigateBack: () => void;
  handleFreeCheckout: () => void;
  handlePaidCheckout: (token: Token) => void;
  isCheckoutPending: boolean;
};

export const NewCheckoutFooter = ({
  onNavigateBack,
  handleFreeCheckout,
  handlePaidCheckout,
  isCheckoutPending,
}: NewCheckoutFooterProps) => {
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);

  const isFreeCheckout = current?.context?.totalPrice <= 0;

  const handleGetNow = () => {
    if (isFreeCheckout) {
      handleFreeCheckout();
    }
  };
  return (
    <div className={styles.checkoutFooter}>
      <button
        className={`${styles.checkoutButton} ${styles.checkoutButtonSecondary}`}
        onClick={onNavigateBack}
      >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5598 13.834L6.44368 9.71782C5.95757 9.23171 5.95757 8.43626 6.44368 7.95015L10.5598 3.83398"
            stroke="black"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Review
      </button>
      <button
        className={`${styles.checkoutButton} ${styles.checkoutButtonPrimary}`}
      >
        Get now
        <StripeCheckout
          label="BUY NOW"
          name="Surf Eye Purchase"
          email={JSON.parse(localStorage.getItem("userInfo")).email}
          panelLabel="Pay"
          ComponentClass="div"
          image={logostripe}
          amount={current.context?.totalPrice * 100}
          currency="EUR"
          token={(token) => {
            handlePaidCheckout(token);
          }}
          stripeKey={process.env.REACT_APP_STRIPE_CLIENT_ID}
        />
      </button>
    </div>
  );
};
