// # DEV
export const api_url = process.env.REACT_APP_API_BASE_URL || "https://dev.surfeye.video/";
export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE || 'PT';

export const LOCATION_BASE_URL = `${api_url}api/v3/locations`;

export const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
export const STREAM_TEST_URL = process.env.REACT_APP_STREAM_TEST_URL;

export const media_url = "https://seawaves.pythonanywhere.com/media";
export const developement_url = "https://surfeye.video/";
// https://api.surfeye.video/
// https://seawaves.pythonanywhere.com
// http://3.71.171.192:8005/
// https://surfeyee.netlify.app/
// http://localhost:3000/
export const endpoints = {
  //VOTING_API~
  GET_VOTING: `${api_url}api/code/voting`,
  POST_VOTING: `${api_url}api/code/voting`,
  //AUTH_API
  SIGNUP: `${api_url}api/auth/signup`,
  // LOGIN: `${api_url}login`,
  LOGIN: `${api_url}api/auth/login`,
  GOOGLE_SIGNIN: `${api_url}api/auth/signupwithgoogle`,
  FORGOT_EMAIL_SENT: `${api_url}api/auth/forgotPasswordlinkSend`,
  FORGOT_TOKEN_CHECK: `${api_url}api/auth/resetPassword`,
  UPDATELASTACTIVE: `${api_url}api/auth/lastactive`,
  // FORGOT_CONFIRMATION: `${api_url}api/auth/forgetConfirmation`,
  // //COUPON_API
  POST_COUPONS: `${api_url}api/code/admincoupens`,
  // POST_COUPONS: `${api_url}admincoupens`,
  GET_COUPONS: `${api_url}api/code/admincoupens`,
  // GET_COUPONS: `${api_url}admincoupens`,
  PUT_COUPONS: `${api_url}api/code/admincoupensUpdate`,
  GETCOUPONSBYFIELDS: `${api_url}api/code/getallcouponsbyfieldsort`,
  APPLY_COUPON: `${api_url}api/code/applycoupen?role=customer`,
  // Admin Users Panel
  GET_USERS: `${api_url}api/code/adminUsers`,
  GETSESSIONOVERVIEW:`${api_url}api/code/getsessionoverview`,
  //FILTER_API
  SEARCHUSER: `${api_url}api/code/searchUser/`,
  GETUSERSBYFIELDS: `${api_url}api/code/getalluserbyfieldsort`,
  GET_DATA: `${api_url}api/waves/uploaddata`,
  // FREE ACCESS API
  INSERT_FREE_ACCESS: `${api_url}api/code/insertfreeaccess`,
  REMOVE_FREE_ACCESS: `${api_url}api/code/deletefreeaccess`,
  // SALES PANEL
  GET_SALES: `${api_url}api/code/getsales`,
  GETSALESBYFIELDS: `${api_url}api/code/getallsalesbyfieldsort`,
  SEARCHSALESRECORD: `${api_url}api/code/searchsales`,
  //COACHING_API
  GET_COUCHING: `${api_url}getCoaching?role=customer`,
  //PAYMENT_API
  POST_PAYMENT: `${api_url}sessionbuy?role=customer`,
  POST_CHECKOUT: `${api_url}api/session/usersessionbuy?role=customer`,
  // GET_CREDITS: `${api_url}sessionbuy?role=customer`,
  GET_CREDITS: `${api_url}api/session/sessionbuy?role=customer`,
  MY_SESSIONS: `${api_url}api/session/usersessionbuy?role=customer`,
  //PROFILE_API
  GET_PROFILE: `${api_url}api/auth/profile?role=customer`,
  //REFERALCODE_API
  REFERAL_CODE: `${api_url}applyReferalcode?role=customer`,
  // DOWNLOAD_FILES:  `${api_url}downloadfile?role=customer&id=c39e00e0-6ad5-4c49-bbea-2736fcf19698`,

  SUBSCRIPTION_CREATE : `${api_url}api/session/subscriptioncreate?role=customer`,
  INSERT_SUBSCRIPTION : `${api_url}api/session/insertsubscription?role=customer`,
  // UPDATE_SUBSCRIPTION : `${api_url}api/session/updatesubscription?role=customer`,
  
  GET_ACTIVE_PASS: `${api_url}api/session/activepassinfo?role=customer`,
  GET_SUBSINFO: `${api_url}api/session/getsubsinfo?role=customer`,
  CANCEL_SUBSCRIPTION: `${api_url}api/session/cancelsubscription?role=customer`,
  CREATE_SESION_FOR_ACTIVE_SUBSCRIPTION: `${api_url}api/session/createsessionforactivesubscription?role=customer`,
  PAYMENT_INTENT: `${api_url}api/session/create-payment-intent?role=customer`,
  LINK_FOR_PAY: `${api_url}api/session/linkforpay?role=customer`,

  NOCOST_CREATE_SESSION: `${api_url}api/session/createsession?role=customer`,
  GETWAVE: `${api_url}api/waves/getwave`,

  GET_REGIONS: `${LOCATION_BASE_URL}/regions`,
  GET_SPOTS: `${LOCATION_BASE_URL}/spots`
};
