import React from "react";

import { NewCheckoutPlans } from "./NewCheckoutPlans";
import { NewCheckoutFooter } from "./NewCheckoutFooter";
import { NewCheckoutTitle } from "./NewCheckoutTitle";
import styles from "./NewCheckout.module.css";
import { PlanType } from "../constants";
import type { Token } from "react-stripe-checkout";

type NewCheckoutProps = {
  onNavigateBack: () => void;
  setPlanType: (type: PlanType) => void;
  handleFreeCheckout: () => void;
  handlePaidCheckout: (token: Token) => void;
  isCheckoutPending: boolean;
};

export const NewCheckout = ({
  onNavigateBack,
  setPlanType,
  handleFreeCheckout,
  handlePaidCheckout,
  isCheckoutPending,
}: NewCheckoutProps) => {
  return (
    <div className={styles.checkout}>
      <NewCheckoutTitle />
      <NewCheckoutPlans setPlanType={setPlanType} />
      <NewCheckoutFooter
        onNavigateBack={onNavigateBack}
        handleFreeCheckout={handleFreeCheckout}
        handlePaidCheckout={handlePaidCheckout}
        isCheckoutPending={isCheckoutPending}
      />
    </div>
  );
};
