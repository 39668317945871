import React, { useEffect, useState } from "react";
import {
  SectionAltRow,
  SectionInfoLink,
  SectionLayout,
  SectionUpdateInfo,
} from "../../../layouts/section";
import {
  forecastDataSet,
  todayForecastDataSet,
  tomorrowForecastDataSet,
} from "./mocks/forecasts";
import {
  Tab,
  TabPanel,
  TabTriggers,
  Tabs,
} from "../../../components/SitePage/tab";
import { ForecastTable } from "./table";
import { ForecastDay } from "./day";
import { cn } from "../../../_theme/utils";
import { ForecastDayData, ForecastData } from "./types";
import { height } from "@mui/system";
import { fmt, unitOf, valueOf, weightOf } from "../tab/utils";
import { CompassDirection } from "../compass";
import { makeMinutesAndSeconds } from "../../../_utils/get-minutes-and-seconds";

export const ForecastsSection = ({ spot }) => {
  const tabs = [
    {
      id: "today",
      label: "Today",
    },
    {
      id: "tomorrow",
      label: "Tomorrow",
    },
    {
      id: "one-week",
      label: "One week",
    },
  ];

  const [todayForecast, setTodayForecast] =
    useState<ForecastDayData>(todayForecastDataSet);
  const [tomorrowForecast, setTomorrowcast] = useState<ForecastDayData>(
    tomorrowForecastDataSet
  );
  const [weeklyForecast, setWeeklyForecast] =
    useState<ForecastData[]>(forecastDataSet);
  const [lastUpdate, setLastUpdate] = useState<string>();

  useEffect(() => {
    console.debug("spot:", spot, "last_updated:", spot?.forcasts?.last_updated);

    setForecast("today", setTodayForecast);
    setForecast("tomorrow", setTomorrowcast);
    setWeekForecast(setWeeklyForecast);

    if (spot?.forcasts?.lastUpdated) {
      setLastUpdate(fmt(spot.forcasts.lastUpdated));
    }

    function setForecast(key, setter) {
      if (!spot?.forcasts?.[key]) {
        return;
      }

      const day = spot.forcasts[key];
      console.debug(`${key}:`, day);
      const newForecast: ForecastDayData = {
        averageSurfDistance: day.surf,
        averageSwell: {
          height: day.swellHeight,
          time: weightOf(day.swell),
          direction: day.swellDirection,
        },
        averageWind: {
          speed: weightOf(day.windSpeed),
          direction: day.windDirection,
        },
        tides: day.tides.map((tide) => ({
          swellTime: makeMinutesAndSeconds(tide.time as string),
          surfDistance: weightOf(tide.height),
        })),
        windType: day.wind,
        energy: {
          value: weightOf(day.swellEnergy),
          unit: unitOf(day.swellEnergy),
        },
        sunrise: makeMinutesAndSeconds(day.sunrise ?? "0:0"),
        sunset: makeMinutesAndSeconds(day.sunset ?? "0:0"),
      };
      setter(newForecast);
      console.debug("Forecast:", newForecast);
    }

    function setWeekForecast(setter) {
      if (!spot?.forcasts) {
        return;
      }
      const weekly = Object.entries(spot.forcasts).filter(
        ([_, week]) => typeof week == "object"
      ) as [
        [
          string,
          {
            surf: string;
            swellHeight: string;
            swell: number;
            swellDirection: CompassDirection;
            wind: CompassDirection;
            windSpeed: string;
            position: number;
          }
        ]
      ];

      console.debug("Weekly: ", weekly);
      const newCast = weekly
        .sort((one, other) => one[1].position - other[1].position)
        .map(([label, week]) => ({
          label: label,
          surf: {
            height: valueOf(week.surf),
            unit: unitOf(week.surf),
          },
          swell: {
            height: weightOf(week.swellHeight),
            time: week.swell,
            direction: week.swellDirection,
            unit: unitOf(week.swellHeight),
          },
          wind: {
            speed: valueOf(week.windSpeed),
            direction: week.wind,
            unit: unitOf(week.windSpeed),
          },
        }));

      setter(newCast);
    }
  }, [spot, lastUpdate]);

  return (
    <SectionLayout className="px-5 pb-3 md:pb-11" relativeId="forecasts">
      <Tabs defaultSelectedTab="today">
        <div className="flex flex-col brand-min:flex-row brand-min:items-center justify-between pb-3 md:pb-9 gap-1 brand-min:gap-2">
          <h2 className="uppercase text-brand-md md:text-2xl lg:text-2xl font-bold">
            Forecasts
          </h2>
          <div className="flex flex-shrink-0 items-center justify-end">
            <TabTriggers
              aria-label="analytics-data-vis"
              className="flex flex-shrink-0 items-center md:px-2 bg-brand-light border border-brand-primary rounded-full w-brand-fit gap:0.5 md:gap-1.5"
            >
              {tabs.map((tab) => (
                <Tab
                  key={tab.id}
                  tab={tab.id}
                  className={cn(
                    "text-xs md:text-[1rem] [&:not(&:first-child)]:border-l py-0 rounded-none border-brand-primary text-brand-primary px-2.5 md:px-3 lg:px-3 text-opacity-50 font-bold  bg-transparent ",
                    "[&.active-tab]:rounded-full [&.active-tab]:-mx-1 [&.active-tab]:md:-mx-3 [&.active-tab]:py-2  [&.active-tab]:md:py-3 [&.active-tab]:bg-brand-primary [&.active-tab]:text-brand-light [&.active-tab]:shadow-brand-1100 [&.active-tab]:px-4"
                  )}
                >
                  {tab.label}
                </Tab>
              ))}
            </TabTriggers>
          </div>
        </div>
        <SectionAltRow className="mb-2 mt-0">
          <SectionUpdateInfo
            updatedAt={lastUpdate}
            className="md:hidden gap-2"
          />
        </SectionAltRow>

        <div className="">
          <TabPanel tab="today">
            <ForecastDay dataSet={todayForecast} />
          </TabPanel>
          <TabPanel tab="tomorrow">
            <ForecastDay dataSet={tomorrowForecast} />
          </TabPanel>
          <TabPanel tab="one-week">
            <ForecastTable dataSet={weeklyForecast} />
          </TabPanel>
        </div>
      </Tabs>
      <SectionAltRow className="justify-end md:justify-between ">
        <SectionInfoLink
          name="Forecasts"
          url="/"
          className="flex-row-reverse md:flex underline"
        />
        <SectionUpdateInfo updatedAt={lastUpdate} className="hidden md:flex" />
      </SectionAltRow>
    </SectionLayout>
  );
};
