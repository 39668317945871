import React from 'react';
import { Icon, type IconProps } from './core';

export const InfoIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1_2643)">
      <path
        d="M6.5 0C7.78558 0 9.04229 0.381218 10.1112 1.09545C11.1801 1.80968 12.0132 2.82484 12.5052 4.01256C12.9972 5.20028 13.1259 6.50721 12.8751 7.76809C12.6243 9.02896 12.0052 10.1872 11.0962 11.0962C10.1872 12.0052 9.02896 12.6243 7.76809 12.8751C6.50721 13.1259 5.20028 12.9972 4.01256 12.5052C2.82484 12.0132 1.80968 11.1801 1.09545 10.1112C0.381218 9.04228 0 7.78558 0 6.5C0 4.77609 0.68482 3.12279 1.90381 1.90381C3.12279 0.684819 4.77609 0 6.5 0ZM6.5 12.0714C7.60192 12.0714 8.6791 11.7447 9.59532 11.1325C10.5115 10.5203 11.2256 9.65014 11.6473 8.63209C12.069 7.61405 12.1793 6.49382 11.9644 5.41307C11.7494 4.33232 11.2188 3.33958 10.4396 2.5604C9.66041 1.78123 8.66768 1.2506 7.58693 1.03562C6.50618 0.82065 5.38595 0.930983 4.36791 1.35267C3.34986 1.77436 2.47972 2.48846 1.86753 3.40468C1.25533 4.3209 0.928572 5.39807 0.928572 6.5C0.928572 7.97763 1.51556 9.39475 2.5604 10.4396C3.60525 11.4844 5.02236 12.0714 6.5 12.0714ZM6.03571 5.24178V9.88464C6.03571 10.0078 6.08463 10.1259 6.1717 10.2129C6.25877 10.3 6.37686 10.3489 6.5 10.3489C6.62313 10.3489 6.74123 10.3 6.8283 10.2129C6.91537 10.1259 6.96428 10.0078 6.96428 9.88464V5.24178C6.96428 5.11865 6.91537 5.00056 6.8283 4.91349C6.74123 4.82641 6.62313 4.7775 6.5 4.7775C6.37686 4.7775 6.25877 4.82641 6.1717 4.91349C6.08463 5.00056 6.03571 5.11865 6.03571 5.24178ZM5.85 3.32429C5.85 3.45284 5.88812 3.57851 5.95954 3.68541C6.03097 3.7923 6.13248 3.87561 6.25125 3.92481C6.37003 3.974 6.50072 3.98688 6.62681 3.9618C6.75289 3.93671 6.86871 3.87481 6.95962 3.7839C7.05052 3.693 7.11243 3.57718 7.13751 3.45109C7.16259 3.32501 7.14972 3.19431 7.10052 3.07554C7.05132 2.95677 6.96801 2.85525 6.86112 2.78383C6.75423 2.71241 6.62856 2.67429 6.5 2.67429C6.32761 2.67429 6.16228 2.74277 6.04038 2.86467C5.91848 2.98656 5.85 3.15189 5.85 3.32429Z"
        fill="#01243C"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2643">
        <rect
          width="13"
          height="13"
          fill="white"
          transform="matrix(-1 0 0 1 13 0)"
        />
      </clipPath>
    </defs>
  </Icon>
);
