import React from 'react';
import { Icon, type IconProps } from './core';

export const SunsetIcon = (props: IconProps) => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="15"
    viewBox="0 0 20 15"
    fill="none"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9054 10.1946H0.402968C0.242522 10.1946 0.112305 10.0644 0.112305 9.90395C0.112305 9.7435 0.242522 9.61328 0.402968 9.61328H4.01572C6.65012 9.61308 6.39576 9.61328 9.38688 9.61328C12.3784 9.61328 12.7601 9.61331 15.1931 9.61328H18.9054C19.0659 9.61328 19.1961 9.7435 19.1961 9.90395C19.1961 10.0644 19.0659 10.1946 18.9054 10.1946ZM3.81459 11.5111C3.65414 11.5111 3.52392 11.3809 3.52392 11.2205C3.52392 11.06 3.65414 10.9298 3.81459 10.9298H15.4938C15.6543 10.9298 15.7845 11.06 15.7845 11.2205C15.7845 11.3809 15.6543 11.5111 15.4938 11.5111H3.81459ZM5.96822 12.8272C5.80777 12.8272 5.67755 12.697 5.67755 12.5366C5.67755 12.3761 5.80777 12.2459 5.96822 12.2459H13.3402C13.5007 12.2459 13.6309 12.3761 13.6309 12.5366C13.6309 12.697 13.5007 12.8272 13.3402 12.8272H5.96822ZM7.32774 14.1438C7.1673 14.1438 7.03708 14.0135 7.03708 13.8531C7.03708 13.6926 7.1673 13.5624 7.32774 13.5624H11.9807C12.1411 13.5624 12.2713 13.6926 12.2713 13.8531C12.2713 14.0135 12.1411 14.1438 11.9807 14.1438H7.32774Z"
      fill="black"
    />
    <path
      d="M10.7918 4.47555C10.6189 3.75112 10.6465 2.99328 10.8716 2.28334C11.0967 1.5734 11.5109 0.938157 12.0697 0.445766C11.3845 0.266744 10.6646 0.26811 9.98009 0.449733C9.29556 0.631355 8.66969 0.987051 8.16336 1.48222C7.65702 1.97739 7.28747 2.59518 7.09063 3.27549C6.89379 3.9558 6.87638 4.67547 7.04009 5.3645C7.20379 6.05354 7.54304 6.68847 8.02483 7.20755C8.50663 7.72662 9.11456 8.11217 9.78951 8.32669C10.4644 8.5412 11.1834 8.57738 11.8765 8.43171C12.5696 8.28603 13.2131 7.96346 13.7446 7.49537C13.0248 7.30713 12.3698 6.92667 11.8496 6.39472C11.3295 5.86278 10.9638 5.19937 10.7918 4.47555Z"
      stroke="black"
      strokeWidth="0.469388"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);
