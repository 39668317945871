import { getMinutesAndSeconds } from '../../../../_utils/get-minutes-and-seconds';

type Datum = {
  _x: string;
  _y: string;
};
type GetLabelsArgs = {
  datum: Datum;
  data: Datum[];
};
export const getLabels = ({ datum, data }: GetLabelsArgs) => {
  console.log("datum:", datum, 'data:', data);
  const datumIndex = data.findIndex(
    (d) => d._x === datum._x && d['oldSurfDistance'] === datum['oldSurfDistance']
  );
  if (datumIndex === data.length - 1 || datumIndex === 0) {
    return ['', ''];
  }
  return [
    `${getMinutesAndSeconds(+datum._x).hours}:${getMinutesAndSeconds(+datum._x).minutes}`,
    `${datum['oldSurfDistance']}m`,
  ];
};
