import axios from "axios";
import React, { useEffect, useMemo, useState } from "react";
import { endpoints } from "../../../Api/Api";
import "./Profile.styles.scss";
import styles from "./Profile.module.css";
import CancelSubs from "../../Modal/cancelSubs";
import ConfirmCancelSubs from "../../Modal/confirmCancelSubs";
import { NewProfile } from "./NewProfile/NewProfile";

const Profile = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [subscriptionInfo, setSubscriptionInfo] = useState({
    passType: "nb",
    renewD: new Date(),
  });

  const dueDate = useMemo(() => {
    return `${monthNames[subscriptionInfo?.renewD?.getMonth()]} ${subscriptionInfo?.renewD?.getDate()}, ${subscriptionInfo?.renewD?.getFullYear()}`;
  }, [subscriptionInfo]);
  const [flag, setFlag] = useState(false);
  const [cancelPopup, setCancelPopup] = useState(false);
  const [confirmCancelPopup, setConfirmCancelPopup] = useState(false);

  useEffect(() => {
    const customertoken = localStorage.getItem("token");
    axios
      .get(endpoints.GET_SUBSINFO, {
        headers: {
          Authorization: `Bearer ${customertoken}`,
        },
      })
      .then((res) => {
        const resDt = res.data;
        setSubscriptionInfo({
          passType: resDt?.passType,
          renewD: new Date(resDt?.nextRenewal),
        });
      })
      .catch((error) => console.log(error));
  }, [flag]);

  const handleCancelSubscription = () => {
    const customertoken = localStorage.getItem("token");
    axios
      .post(
        endpoints.CANCEL_SUBSCRIPTION,
        {},
        {
          headers: {
            Authorization: `Bearer ${customertoken}`,
          },
        },
      )
      .then((res) => {
        setFlag(true);
        setCancelPopup(false);
        setConfirmCancelPopup(true);
      })
      .catch((error) => console.log(error));
  };
  const userInfo = localStorage.getItem("userInfo");
  let UserName = "XYZ";
  if (userInfo) {
    const userInfoParse = JSON.parse(userInfo);
    UserName = userInfoParse?.fname;
  }
  return (
    <>
      <div className={`${styles.Profile}`}>
        <div className={`${styles.profileHeading}`}>HI, {UserName}</div>
        <NewProfile
          subscriptionInfo={subscriptionInfo}
          showCancelSubscriptionPopup={() => setCancelPopup(true)}
          dueDate={dueDate}
        />
      </div>

      {/* popup */}
      {cancelPopup ? (
        <div className={styles.ModalStart}>
          <div className={styles.background}></div>
          <div className={styles.ModalBox}>
            <div className={styles.Heading}>
              <h2>CANCEL SUBSCRIPTION</h2>
              <button
                className={styles.closeFaq}
                onClick={() => setCancelPopup(false)}
              >
                X close
              </button>
            </div>
            <div className={styles.Box}>
              <div className={styles.boxText}>
                <h3>
                  ARE YOU SURE YOU WANT TO CANCEL SURF EYE{" "}
                  <span> UNLIMITED</span> ?
                </h3>
                <h4>
                  IF YOU CANCEL NOW, YOU WILL STILL HAVE ACCESS UNTIL{" "}
                  {`${monthNames[subscriptionInfo?.renewD?.getMonth()]} ${subscriptionInfo?.renewD?.getDate()}, ${subscriptionInfo?.renewD?.getFullYear()}`}
                  .
                </h4>
              </div>
              <div className={styles.boxButton}>
                <button
                  className={styles.themeColor}
                  onClick={() => setCancelPopup(false)}
                >
                  BACK
                </button>
                {/* cancel subscription api call */}
                <button
                  className={styles.whiteColor}
                  onClick={handleCancelSubscription}
                >
                  CANCEL
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {confirmCancelPopup ? (
        <div className={styles.ModalStart}>
          <div className={styles.background}></div>
          <div className={styles.ModalBox}>
            <div className={styles.Heading}>
              <h2>WE ARE SORRY TO SEE YOU GO</h2>
              <button
                className={styles.closeFaq}
                onClick={() => setConfirmCancelPopup(false)}
              >
                X close
              </button>
            </div>
            <div className={styles.Box}>
              <div className={styles.boxText}>
                <h3>YOUR SUBSCRIPTION HAS BEEN CANCELLED</h3>
                <h4>
                  YOU WILL STILL HAVE UNLIMITED ACCESS UNTIL{" "}
                  {`${monthNames[subscriptionInfo?.renewD?.getMonth()]} ${subscriptionInfo?.renewD?.getDate()}, ${subscriptionInfo?.renewD?.getFullYear()}`}
                  .
                </h4>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Profile;
