const CYCLE = 60;

export const getMinutesAndSeconds = (seconds: number) => {
  const hours = Math.floor(seconds / CYCLE);
  const remainingSeconds = seconds - hours * CYCLE;

  return { hours, minutes: remainingSeconds };
};

export const makeMinutesAndSeconds = (text: string) => {
  const [_, hours, minutes] = text.match(/.*?(\d+?):(\d+)(?::\d+)?.*/);
  return parseInt(hours) * CYCLE + parseInt(minutes);
}