import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import React from "react";
import { hydrate, render } from "react-dom";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import introspectionResult from "./gql/graphql.schema.json";
import { buildClientSchema, IntrospectionQuery } from "graphql";
import { withScalars } from "apollo-link-scalars";
import { GraphQLDate, GraphQLDateTime } from "graphql-scalars";
import { api_url } from "./Api/Api";
import { setContext } from "@apollo/client/link/context";
import { Root } from "./Root";

const httpLink = createHttpLink({
  uri: `${api_url}api/graphql`,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery
);

const typesMap = {
  Date: GraphQLDate,
  DateTime: GraphQLDateTime,
};

// console.log(process.env);
export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(withScalars({ schema, typesMap })).concat(httpLink),
  // uri: "/api/graphql",
  // headers: {
  //   authorization: `Bearer ${localStorage.getItem('token')}`
  // }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={apolloClient}>
    {/* <CartContextProvider> */}
    <Root />
    {/* </CartContextProvider> */}
  </ApolloProvider>
);

reportWebVitals();
