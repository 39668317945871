import React, { ChangeEvent, FocusEventHandler, useEffect } from "react";
import styles from "./NewCheckoutPlans.module.css";
import { useState, useMemo } from "react";
import { useActor } from "@xstate/react";
import { useWaveContext } from "../../../../context/CartContext";
import { PLAN_TYPES, PlanType } from "../constants";
import { useDebouncedCallback } from "use-debounce";

type Plan = {
  name: string;
  title: string;
  description: string;
  pricing: number;
  discountPrice?: number;
  period?: string;
  infoText?: string[];
  priceIndex: number;
  type: PlanType;
  isHighlighted?: boolean;
};

type CheckoutPlanListItemProps = {
  isActive?: boolean;
  plan: Plan;
  onSelectPlan?: () => void;
};

const CheckoutPlanListItem = ({
  isActive,
  plan,
  onSelectPlan,
}: CheckoutPlanListItemProps) => {
  return (
    <div
      className={`${styles.checkoutPlanItem} ${isActive && styles.checkoutPlanItemActive} ${plan.isHighlighted ? styles.checkoutPlanItemHighlightedActive : styles.checkoutPlanItemNormalActive}`}
      onClick={onSelectPlan}
    >
      <div className={styles.checkoutItemHeader}>
        <div className={styles.checkoutPlanItemTitle}>{plan.title}</div>
      </div>
      <div className={styles.checkoutPlanItemPriceContainer}>
        <div className="">
          <div className={styles.checkoutPlanItemDescription}>
            {plan.description}
          </div>
          {plan.infoText && plan.infoText.length > 0 ? (
            <div className={styles.checkoutPlanItemInfoText}>
              {plan.infoText.map((text, index) => (
                <p key={index}>{text}</p>
              ))}
            </div>
          ) : null}
        </div>

        <div className={styles.checkoutPlanItemPrice}>
          {plan?.discountPrice ? (
            <div className={styles.checkoutPlanItemLimitedOffer}>
              Limited offer
            </div>
          ) : null}
          <span className={styles.checkoutPlanItemPriceValue}>
            {plan?.discountPrice ? (
              <>
                <span className={styles.checkoutPlanItemCrossedValue}>
                  {plan.pricing.toFixed(2)}€
                </span>
                <span>{plan.discountPrice.toFixed(2)}€</span>
              </>
            ) : (
              <span className="preferred">{plan.pricing.toFixed(2)}€</span>
            )}
          </span>
          {plan.period ? (
            <span className={styles.checkoutPlanItemPricePeriod}>
              /{plan.period}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};

type CheckoutPlanListProps = {
  plans: Plan[];
  handleSelectPlan: (priceIndex: number, type: PlanType) => void;
  checkSelectedPlan: (name: string) => boolean;
};

const CheckoutPlanList = ({
  plans,
  handleSelectPlan,
  checkSelectedPlan,
}: CheckoutPlanListProps) => {
  return (
    <div className={styles.checkoutPlans}>
      {plans.map((plan, index) => (
        <CheckoutPlanListItem
          plan={plan}
          key={index}
          onSelectPlan={() => handleSelectPlan(plan.priceIndex, plan.type)}
          isActive={checkSelectedPlan(plan.name)}
        />
      ))}
    </div>
  );
};

const CheckoutDiscount = () => {
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);
  const [couponCode, setCouponCode] = useState("");

  const refetchDb = useDebouncedCallback(() => {
    if (couponCode) {
      waveService.send({
        type: "coupon",
        couponCode,
      });
    }
  }, 1000);

  useEffect(() => {
    refetchDb();
  }, [couponCode]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCouponCode(event.target.value);
  };

  const handleBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    setCouponCode(event.target.value);
  };

  const isPerMonth = current?.context?.selectedProduct?.name === "per-month";

  const isValid = current.context.coupon?.valid;
  const isDirty = couponCode !== "";

  return (
    <div className={styles.checkoutDiscountForm}>
      <input
        type="text"
        value={couponCode}
        placeholder="Insert here"
        className={`${styles.checkoutDiscountFormInput} ${isDirty && (isValid ? styles.checkoutDiscountFormSuccessInput : styles.checkoutDiscountFormErrorInput)}`}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      {isDirty ? (
        <span
          className={`${styles.checkoutDiscountFormMessage} ${isDirty && (isValid ? styles.checkoutDiscountFormSuccessMessage : styles.checkoutDiscountFormErrorMessage)}`}
        >
          {isValid ? (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7406 8.0014C14.7406 4.31909 11.7558 1.33398 8.07389 1.33398C4.39199 1.33398 1.40723 4.31909 1.40723 8.0014C1.40723 11.6837 4.39199 14.6688 8.07389 14.6688C11.7558 14.6688 14.7406 11.6837 14.7406 8.0014Z"
                stroke="#287D3C"
                strokeWidth="1.5"
                strokeMiterlimit="10"
              />
              <path
                d="M4.40723 7.98676L6.86669 10.334L11.5739 5.83398"
                stroke="#287D3C"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.07389 14.6673C11.7558 14.6673 14.7406 11.6825 14.7406 8.00065C14.7406 4.31875 11.7558 1.33398 8.07389 1.33398C4.392 1.33398 1.40723 4.31875 1.40723 8.00065C1.40723 11.6825 4.392 14.6673 8.07389 14.6673Z"
                stroke="#DA1414"
                stroke-width="1.5"
              />
              <path
                d="M8.0791 4V9M8.0791 11.8V11.5"
                stroke="#DA1414"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          )}
          {isValid
            ? (current?.context?.coupon?.message ?? "Discount applied!")
            : "Code is not valid"}
        </span>
      ) : null}
    </div>
  );
};

type CheckoutDetailsProps = {
  currentPlan: Plan;
};

const CheckoutDetails = ({ currentPlan }: CheckoutDetailsProps) => {
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);

  const planDetails = useMemo(() => {
    return {
      pricePerItem: null,
      ...currentPlan,
    };
  }, [current]);

  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    setOpen((open) => !open);
  };

  return (
    <div className={styles.checkoutPlanDetails}>
      <div className={styles.checkoutPlanDetailsInnerContainer}>
        <div className={styles.checkoutPlanDetailsFirstContainer}>
          <p className={styles.checkoutPlanDetailsTitle}>Details</p>
          <div className={styles.checkoutPlanDetailsItemContainer}>
            <p className={styles.checkoutPlanDetailsItem}>
              <span className="">
                {planDetails?.pricePerItem
                  ? `${planDetails.pricePerItem}€ X `
                  : ""}{" "}
                {currentPlan.title}
              </span>
              <span className="">{currentPlan.pricing.toFixed(2)}€</span>
            </p>
            <p className={styles.checkoutPlanDetailsItem}>
              <span className="">Discount</span>
              <span className="">
                {currentPlan.discountPrice ? "-" : ""}
                {(
                  currentPlan.pricing -
                  (currentPlan.discountPrice ?? currentPlan.pricing)
                ).toFixed(2)}
                €
              </span>
            </p>
            <p className={styles.checkoutPlanDetailsItem}>
              <span className="">Tax (included)</span>
            </p>
          </div>
          <p className={styles.checkoutPlanDetailsTotalItem}>
            <span className="">Total</span>
            <span className="">
              {currentPlan?.discountPrice
                ? currentPlan.discountPrice.toFixed(2)
                : currentPlan.pricing.toFixed(2)}
              €
            </span>
          </p>
        </div>

        <div
          className={styles.checkoutPlanDetailsDiscountItem}
          onClick={toggle}
        >
          <p className="">Discount code</p>
          <button
            className={`${styles.checkoutDetailsDropdownButton} ${styles.chevronIcon} ${isOpen && styles.chevronIconActive}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="33"
              height="33"
              viewBox="0 0 33 33"
              fill="none"
            >
              <path
                d="M5.41504 11.5332L13.6485 19.7666C14.6246 20.7427 16.2161 20.7427 17.1816 19.7666L25.415 11.5332"
                stroke="black"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
        {isOpen ? <CheckoutDiscount /> : null}
      </div>
    </div>
  );
};

type NewCheckoutPlansProps = {
  setPlanType: (type: PlanType) => void;
};

export const NewCheckoutPlans = ({ setPlanType }: NewCheckoutPlansProps) => {
  const { waveService } = useWaveContext();
  const [current] = useActor(waveService);

  const plans = useMemo(
    () => [
      {
        name: "per-month",
        title: "Surf Eye Unlimited",
        description: "30 days of unlimited waves",
        pricing: 9.99,
        period: "month",
        infoText: ["Renews automatically", "Cancel anytime"],
        priceIndex: 2,
        type: PLAN_TYPES.monthPass,
        isHighlighted: true,
      },
      {
        name: "per-day",
        title: "Day Pass",
        description: "Limited for one location",
        priceIndex: 1,
        pricing: 7.99,
        period: "day",
        type: PLAN_TYPES.dayPass,
      },
      {
        name: "per-wave",
        title: `${current?.context?.cart?.waves?.length} ${current?.context?.cart?.waves?.length === 1 ? "Wave" : "Waves"}`,
        description: "4.99€ per wave",
        pricing: current.context.products[0]?.totalWavePrice,
        priceIndex: 0,
        pricePerItem: 4.99,
        type: PLAN_TYPES.wavePass,
      },
    ],
    [current],
  );

  const [currentPlan, setCurrentPlan] = useState<Plan>(plans[2]);

  useEffect(() => {
    handleSelectPlan(0, PLAN_TYPES.wavePass);
  }, []);

  const handleSelectPlan = (priceIndex: number, type: PlanType) => {
    const filteredPlans = plans.filter(
      (plan) => plan.priceIndex === priceIndex && plan.type === type,
    );

    if (filteredPlans.length > 0) {
      setCurrentPlan(filteredPlans[0]);
    }

    waveService.send({
      type: "selectProduct",
      product: current.context.products[priceIndex],
    });

    setPlanType(type);
  };

  const checkSelectedPlan = (name: string) => {
    return current?.context?.selectedProduct?.name === name;
  };

  return (
    <div className={styles.checkoutContainer}>
      <CheckoutPlanList
        plans={plans}
        handleSelectPlan={handleSelectPlan}
        checkSelectedPlan={checkSelectedPlan}
      />
      <CheckoutDetails currentPlan={currentPlan} />
    </div>
  );
};
