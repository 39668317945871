// 'use client';
import React from 'react';

import './chart.scss';

import { ForecastDayData } from '../types';
import { VictoryArea, VictoryClipContainer, VictoryContainer, VictoryLabel } from 'victory';
import { SunriseIcon, SunsetIcon } from '../../../../assets/SitePage/icons';
import { getLabels } from './utils';
import { areaChartStyles, labelStyles } from './styles';
import { ForecastChartSegment } from './segment';
import { getMinutesAndSeconds } from '../../../../_utils/get-minutes-and-seconds';

type ForecastChartProps = {
  tides: ForecastDayData['tides'];
  sunrise: number;
  sunset: number;
};

export const ForecastChart = ({ tides, sunrise, sunset }: ForecastChartProps) => {
  const TIDE_SCALE = 3.5;
  const SWELL_SCALE = 1.0;
  const minTide = tides.reduce((acc, cur) => Math.min(acc, cur.surfDistance), Infinity);
  const maxTide = tides.reduce((acc, cur) => Math.max(acc, cur.surfDistance), -Infinity);
  const minSwell = tides.reduce((acc, cur) => Math.min(acc, cur.swellTime), Infinity);
  const maxSwell = tides.reduce((acc, cur) => Math.max(acc, cur.swellTime), -Infinity);
  const tideRange = Math.ceil(maxTide - minTide) * TIDE_SCALE;
  const swellRange = Math.ceil(maxSwell - minSwell) * SWELL_SCALE;
  const TIDE_SHIFT = tideRange / 3.0;
  
  const sortedTides = tides.sort(
    (prevTide, nextTide) => prevTide.swellTime - nextTide.swellTime
  ).map((tide) => ({
    ...tide,
    oldSurfDistance: tide.surfDistance,
    surfDistance: tide.surfDistance - minTide + TIDE_SHIFT
  }));

  console.log('swellRange:', swellRange);

  const fuse = ({ hours, minutes }) => hours + ':' + minutes;

  return (
    <div className="px-2">
      <div className="brand-min-desktop:max-w-[609px] w-full h-full flex-1 aspect-[335/101] md:aspect-[609/159] rounded-brand-200  flex  items-end  relative ">
        <ForecastChartSegment
          className="absolute left-0 w-1/4 h-full rounded-l-3xl bg-black bg-opacity-10"
          label={
            <div className="flex gap-1.5 items-center">
              <span className="text-xs italic font-normal opacity-50">
                {fuse(getMinutesAndSeconds(sunrise))}
              </span>
              <SunriseIcon />
            </div>
          }
        />
        <ForecastChartSegment className="absolute inset-x-1/4  h-full  bg-brand-grey bg-opacity-20" />
        <ForecastChartSegment
          className="absolute right-0 w-1/4  h-full rounded-r-3xl bg-black bg-opacity-10"
          label={
            <div className="flex gap-1.5 items-center">
              <span className="text-xs italic font-normal opacity-50">
                {fuse(getMinutesAndSeconds(sunset))}
              </span>
              <SunsetIcon />
            </div>
          }
          labelPosition="left"
        />
        <div className="h-full w-full absolute inset-0 z-50">
          <VictoryArea
            data={sortedTides}
            maxDomain={{
              x: swellRange,
              y: tideRange
            }}
            labels={getLabels}
            labelComponent={
              <VictoryLabel
                dy={-10}
                lineHeight={[1.5, 1.5]}
                style={labelStyles}
              />
            }
            x="swellTime"
            y="surfDistance"
            interpolation="natural"
            scale={{ x: 'time', y: 'linear' }}
            padding={0}
            width={609}
            height={159}
            style={areaChartStyles}
            containerComponent={
              <VictoryContainer preserveAspectRatio="none" responsive={true} />
            }
          />
        </div>
      </div>
    </div>
  );
};
