import { ForecastData, ForecastDayData } from "../types";

export const forecastDataSet: ForecastData[] = [
  {
    label: "Today",
    surf: {
      height: "2-3",
      unit: "m",
    },
    swell: {
      time: 131,
      direction: "WNW",
    },
    wind: {
      speed: 13,
      direction: "SSE",
      unit: "kph",
    },
  },
  {
    label: "Tmrw",
    surf: {
      height: "2-3",
      unit: "m",
    },
    swell: {
      time: 131,
      direction: "WNW",
    },
    wind: {
      speed: 13,
      direction: "SSE",
      unit: "kph",
    },
  },
  {
    label: "Fri",
    surf: {
      height: "2-3",
      unit: "m",
    },
    swell: {
      time: 131,
      direction: "WNW",
    },
    wind: {
      speed: 13,
      direction: "SSE",
      unit: "kph",
    },
  },
  {
    label: "Sat",
    surf: {
      height: "2-3",
      unit: "m",
    },
    swell: {
      time: 131,
      direction: "WNW",
    },
    wind: {
      speed: 13,
      direction: "SSE",
      unit: "kph",
    },
  },
  {
    label: "Sun",
    surf: {
      height: "2-3",
      unit: "m",
    },
    swell: {
      time: 131,
      direction: "WNW",
    },
    wind: {
      speed: 13,
      direction: "SSE",
      unit: "kph",
    },
  },
];

export const todayForecastDataSet: ForecastDayData = {
  averageSurfDistance: "2-3",
  averageSwell: {
    height: "2m",
    time: 131,
    direction: "WNW",
  },
  averageWind: {
    speed: +"13",
    direction: "SSE",
  },
  tides: [
    {
      swellTime: 450,
      surfDistance: 1.8,
    },
    {
      swellTime: 550,
      surfDistance: 1.4,
    },
    {
      swellTime: 0,
      surfDistance: 1.5,
    },
    {
      swellTime: 80,
      surfDistance: 1.4,
    },
    {
      swellTime: 200,
      surfDistance: 2.4,
    },
    {
      swellTime: 340,
      surfDistance: 1.2,
    },
  ],
  windType: "offshore",
  energy: {
    value: 2810,
    unit: "kj",
  },
  sunrise: 12.2,
  sunset: 11.0,
};

export const tomorrowForecastDataSet: ForecastDayData = {
  averageSurfDistance: "2-3",
  averageSwell: {
    height: "2m",
    time: 131,
    direction: "WNW",
  },
  averageWind: {
    speed: +"13",
    direction: "SSE",
  },
  tides: [
    {
      swellTime: 0,
      surfDistance: 1.5,
    },
    {
      swellTime: 40,
      surfDistance: 1.4,
    },
    {
      swellTime: 190,
      surfDistance: 2.1,
    },
    {
      swellTime: 340,
      surfDistance: 2,
    },
    {
      swellTime: 450,
      surfDistance: 1.8,
    },
    {
      swellTime: 550,
      surfDistance: 2.5,
    },
  ],
  windType: "offshore",
  energy: {
    value: 2810,
    unit: "kj",
  },
  sunrise: 11.1,
  sunset: 10.1,
};
