import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "./Redux/store";
import ForgotPassword from "./components/ForgotPassword";
import MySession from "./components/Session/Mysession/MySession";
import { WatchWithContext } from "./components/Session/Watch/WatchWithContext";
// import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import Coupons from "./pages/Admin/Coupons";
import Users from "./pages/Admin/Users";
import UserManagement from "./pages/Admin/UserManagement";
import SalesManagement from "./pages/Admin/SalesManagement";
import CouponsManagement from "./pages/Admin/CouponsManagement";
import { AppLayout } from "./pages/AppLayout";
import LoginSignUpPage from "./pages/Authentication";
import { LandingPage } from "./pages/LandingPage/index";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import {
  Route,
  Routes,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  useRouteError,
  useNavigate,
} from "react-router-dom";
import { PlaceSelector } from "./components/Session/Watch/PlaceSelector";
import {
  DaySelector,
  loadDaySelectorData,
} from "./components/Session/Watch/DaySelector";
import { TimeSelector } from "./components/Session/Watch/TimeSelector";
import { loadWaves, SelectWaves } from "./components/Session/Watch/SelectWaves";
import { Checkout2 } from "./components/Session/Checkout/Checkout2";
import { useWavesLogout } from "./utils/hooks";
import SessionPlayer from "./components/Session/SessionPlayer/SessionPlayer";
import { CartWaves } from "./components/Session/Watch/cartWaves";
import Profile from "./components/Session/Profile/Profile2";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// import BusinessPage from "../src/pages/business-landing/index";
import SitePage from "./pages/SitePage";
import ProtectedRoute from "./utils/protectedRoute";
import Hotjar from "@hotjar/browser";
import { CheckoutOld } from "./components/Session/Checkout/CheckoutOld";

// Disable right-click
document.addEventListener("contextmenu", (e) => e.preventDefault());

function ctrlShiftKey(e, keyCode) {
  return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
}

document.onkeydown = (e) => {
  // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
  if (
    e.keyCode === 123 ||
    ctrlShiftKey(e, "I") ||
    ctrlShiftKey(e, "J") ||
    ctrlShiftKey(e, "C") ||
    (e.ctrlKey && e.keyCode === "U".charCodeAt(0))
  )
    return false;
};

const ErrorBoundary = () => {
  let error: any = useRouteError();
  const handleCustomerLogout = useWavesLogout();
  console.error(error);
  useEffect(() => {
    handleCustomerLogout();
  }, []);

  // Uncaught ReferenceError: path is not defined
  return <div></div>;
};

export const Root = () => {
  useEffect(() => {
    Hotjar.init(3540830, 6); // Initialize Hotjar
  }, []);
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_CLIENT_ID);
  const [dynamicPrice, setDynamicPrice] = useState(111 * 100);
  const handleForPriceAmount = (amount: number) => {
    setDynamicPrice(amount);
  };
  const options: any = {
    mode: "payment",
    amount: dynamicPrice,
    currency: "eur",
    wallet: "always",
    appearance: {
      variables: {
        // This controls the border-radius of the rendered Express Checkout Element
        borderRadius: "10px",
      },
    },
    // Specify a type per payment method
    // Defaults to 'buy' for Google and 'plain' for Apple
    buttonType: {
      googlePay: "Buy with",
      applePay: "Buy with",
    },
    // Specify a theme per payment method
    // Default theme is based on appearance API settings
    buttonTheme: {
      applePay: "white-outline",
    },
    // Height in pixels. Defaults to 44. The width is always '100%'.
    // buttonHeight: 40
  };
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route index element={<LandingPage />} />
        <Route path="/auth" element={<LoginSignUpPage />}></Route>
        <Route
          path="/session"
          element={<AppLayout title="Watch" />}
          errorElement={<ErrorBoundary />}
        >
          <Route index element={<PlaceSelector />} />
          <Route
            path="location/:location"
            loader={loadDaySelectorData}
            element={<DaySelector />}
          />
          <Route
            path="location/:location/day/:day"
            element={<TimeSelector />}
          />
          <Route
            path="location/:location/day/:day/:time"
            loader={loadWaves}
            element={<SelectWaves />}
          />
          <Route
            path="checkout"
            element={
              <Elements stripe={stripePromise} options={options}>
                <Checkout2
                  handleForPriceAmount={handleForPriceAmount}
                  amount={dynamicPrice}
                />
                {/*<Checkout2 handleForPriceAmount={handleForPriceAmount} amount={dynamicPrice}/>*/}
              </Elements>
            }
          />
        </Route>
        <Route path="/mysession" element={<AppLayout title="Watch" />}>
          <Route index element={<MySession />} />
          <Route path="player/:sessionId" element={<SessionPlayer />} />
        </Route>
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route
          path="/admin/coupons"
          element={
            <ProtectedRoute>
              <CouponsManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <ProtectedRoute>
              <UserManagement />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/sales"
          element={
            <ProtectedRoute>
              <SalesManagement />
            </ProtectedRoute>
          }
        />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />

        <Route path="/profile" element={<AppLayout title="Watch" />}>
          <Route index element={<Profile />} />
        </Route>

        <Route path="/cart" element={<AppLayout title="Watch" />}>
          <Route index element={<CartWaves />} />
        </Route>

        {/* <Route path="/wavepools">
          <Route index element={<BusinessPage />} />
        </Route> */}

        <Route path="/beachcam/:stream" element={<SitePage />} />
      </Route>,
    ),
  );
  return (
    <>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </>
  );
};
